import * as React from "react"
import Profile from "../components/organisms/profile"
import HatenaArticles from "../components/organisms/hatena-articles"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BreadcrumbList from "../components/organisms/BreadcrumbList"
import { Heading } from "@chakra-ui/react"
import { useStaticQuery , graphql} from "gatsby"



const Portfolio = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const sideBarContents = [
    <Profile headerDisplay={true} />,
    <HatenaArticles />,
  ]

  return (
    <Layout
      location={location}
      title={siteTitle}
      sideBarContents={sideBarContents}
      isTwoColumn={true}
      bgColor="white"
    >
      <Seo title="ポートフォリオ" />
      <BreadcrumbList
        lists={[
          { name: "Blog", slug: "/" },
          { name: "Portfolio", slug: "/portfolio" }
        ]}
      />
      <Heading
        as="h1"
        fontSize="2rem"
        pl="0.5rem"
        mx="2rem"
        mb={{ base: "2em", lg: "3em" }}
        py="0.5rem"
        borderBottom="solid 2px"
        borderColor="gray.200"
      >
        ポートフォリオ
      </Heading>
    </Layout>
  )
}

export default Portfolio
